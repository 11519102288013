
import { defineComponent, ref, onMounted, watch } from "vue";
import Datatable from "@/components/agencies/Agencies.vue";
import DateRange from "@/components/daterange/DateRange.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import _ from "lodash";
import ApiService from "@/core/services/ApiService";
import SolanaServices from "@/core/services/SolanaServices";
import { Field, ErrorMessage } from "vee-validate";
import { useRoute } from 'vue-router';

export default defineComponent({
  name: "Apartados",
  components: {
    Datatable,
    DateRange,
    Field,
  },
  setup() {
    const loading = ref<boolean>(false);
    const keywords = ref<string>("");
    const showing = ref<number>(10);
    const estatusApartado = ref<string>("Estatus");
    let searchWord = ref<string>("");
    const searchByAgency = ref<string>("Agencia");
    const headerConfig = ref([
      {
        name: "Cliente",
        key: "cliente",
        sortable: true,
      },
      {
        name: "Correo",
        key: "correo",
        sortable: true,
      },
      {
        name: "Teléfono",
        key: "telefono",
        sortable: true,
      },
      {
        name: "Estatus cargo",
        key: "estatus",
        sortable: true,
      },
      {
        name: "Fecha",
        key: "fecha",
        sortable: true,
      },
      {
        name: "Agencia",
        key: "agencia",
        sortable: true,
      },
      {
        name: "Vehículo",
        key: "vehiculo",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: false,
      },
    ]);
    const tableData1 = ref<any>([]);
    const links = ref<any>([]);
    const total = ref<number>(0);
    const from = ref<number>(0);
    const to = ref<number>(0);
    const noResults = ref<boolean>(false);
    const buscar = ref<string>("");
    const statuses = ref<any>([{}]);
    const firstDate = ref<string>("");
    const secondDate = ref<string>("");
    const tipoRol = ref<string>("Agencia");  
    const agenciesCat = ref<string[]>([]);
    const params = ref<any>();
    const idAgency = ref<any>("");
    const route=useRoute();


    /**
     * @method clearData
     * @params none
     * @return Clear data of table
     */ 
     const clearData = () => {
      tableData1.value.splice(0);
      links.value.splice(0);
      total.value = 0;
      from.value = 0;
      to.value = 0;
    }

    watch(
      () => route.params.id,
      async newId => {
        clearData();
        idAgency.value = newId;
        await getApartados();
      }
    )

    const getPagination = async (link) => {
      clearData();
      let urlProd = link.url;
      if(urlProd.search('seminuevossolana') > 0){
        urlProd = urlProd.replace('http', 'https');
      }
      await getApartados(urlProd);
    };

    const showingSet = async () => {
      clearData();
      await getApartados(params.value);
    };

    /**
     * @method searching
     * @params word, valuador, tipo, estatus, agencia
     * @return Valuadores list
     */
     const searching = async ( ) => {
      let url = "api/vehicle/apartados?showing="+showing.value;
      if(buscar.value != ""){
        url += "&buscar="+buscar.value;
      }
      if(searchByAgency.value != "" && searchByAgency.value != "Agencia"){
        url += "&agency="+searchByAgency.value;
      }
      if(estatusApartado.value != "" && estatusApartado.value != "Estatus"){
        url += "&status="+estatusApartado.value;
      }
      if(firstDate.value != ''){
        url += "&fecha_ini=" + firstDate.value;
      }
      if(secondDate.value != ''){
        url += "&fecha_fin=" + secondDate.value;
      }

      params.value = url;
      clearData();
      await getApartados(url);
    }

    /**
     * @method searchDebouncer
     * @params none
     * @return appointments 
     */
     const searchDebouncer = _.debounce(()=>{
     searching();
    },500);

    const getApartados = async (url = "") => {
      let urlOrigin = "/api/vehicle/apartados?showing="+showing.value;
      if(url != ""){
        urlOrigin = url;
      }
      if(idAgency.value != "" && idAgency.value != undefined){
        urlOrigin += "&agency="+idAgency.value; 
      }
      const {data} = await ApiService.get(urlOrigin);
      let apartados = data.data.data;
      if(tipoRol.value == 'Múltiples agencias'){
        headerConfig.value.splice(5, 1);
      }
      if(apartados.length > 0){
        apartados.forEach((val) => {
          let fecha = val.fecha.split('-');
          let obj = {};
          if(tipoRol.value == 'Corporativo'){
            obj = {
              id: val.uuid,
              cliente: val.cliente,
              correo: val.correo,
              telefono: val.telefono,
              estatus: val.status,
              fecha: fecha[2]+"/"+fecha[1]+"/"+fecha[0],
              agencia: val.agencia,
              vehiculo: val.vehiculo,
            }
          }else{
            obj = {
              id: val.uuid,
              cliente: val.cliente,
              correo: val.correo,
              telefono: val.telefono,
              estatus: val.status,
              fecha: fecha[2]+"/"+fecha[1]+"/"+fecha[0],
              vehiculo: val.vehiculo,
            }
          }
          tableData1.value.push(obj);
        });
        links.value = data.data.links;
        total.value = data.data.total;
        from.value = data.data.from;
        to.value = data.data.to;
        noResults.value = false;
      }else{
        noResults.value = true;
      }
    }

    /**
     * @method getEstatuses
     * @params none
     * @return Estatuses list
     */
     const getEstatuses = async () => {
      const {data} = await ApiService.get("api/apartados/status");
      statuses.value = data.data;
    }

    /**
     * @method setValuador
     * @params none
     * @return appointments 
     */
     const setEstatus = () => {
      searching();
    };

    const searchCatsAgency = async () => {
      searching();
    };

     /**
     * @method getDates
     * @params none
     * @return dates from calendar
     */
     const getDates = (evt) => {
      if(evt.firstDate != undefined){
        firstDate.value = evt.firstDate;
      }
      if(evt.secondDate != undefined){
        secondDate.value = evt.secondDate;
      }
      searching();
    };

    const getAgencies = async () => {
      try {
        const { data } = await axios.get(process.env.VUE_APP_API_URL+'/api/site/info-agencies');
        agenciesCat.value = data.data;
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(async () => {
      const {data} = await SolanaServices.getTipoRol();
      tipoRol.value = data.data[0].type;
      console.log(tipoRol.value);
      await getAgencies();  
      await getApartados();
      await getEstatuses();
    });

    return {
      headerConfig,
      loading,
      keywords,
      tableData1,
      links,
      getPagination,
      total,
      searchWord,
      showing,
      showingSet,
      from,
      to,
      noResults,
      getApartados,
      clearData,
      buscar,
      searching,
      searchDebouncer,
      statuses,
      getEstatuses,
      estatusApartado,
      setEstatus,
      getDates,
      firstDate,
      secondDate,
      tipoRol,
      searchCatsAgency,
      searchByAgency,
      agenciesCat,
      getAgencies,
      params,
      idAgency,
      route
    };
  },
});
